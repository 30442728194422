
<template>
    <b-card style="margin: 0em 2em 0em 2em">
      <div class="mt-4 mb-2 text-center">
        <h1>
          แพ็กเกจ/ราคา <br />
          เลือกเซ็ตแพ็กเกจ ที่เหมาะกับคุณ
        </h1>
        <h4>
          มาครบ จบในเซ็ต <br />
          เซ็ตเครื่อง SOCIO Wi-Fi พร้อมระบบ
        </h4>
      </div>
  
      <!-- <b-row>
        <b-col >dsfsd</b-col>
        <b-col >sdfsdf</b-col>
      </b-row> -->
      <swiper
        class="swiper-responsive-breakpoints px-4 py-2"
        :options="swiperOptions"
      >
        <swiper-slide v-for="(product, index) in data" :key="index">
          <div xl="4" md="12">
            <!-- <div align="center">
              <h3 class="text-truncate mb-0">
                {{ product.name }}
              </h3>
              <small class="text-body">by {{ product.brand }}</small>
            </div> -->
            <div class="img-container w-50 mx-auto py-75">
              <b-img :src="product.image" fluid />
            </div>
            <div style="margin-left: 20px">
              <div v-html="product.detail"></div>
              <!-- <ul class="unstyled-list list-inline mb-25">
                <li v-for="star in 5" :key="star" class="ratings-list-item">
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    class="mr-25"
                    :class="[
                      { 'fill-current': star <= product.rating },
                      star <= product.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul> -->
              <h2 class="card-text mb-0">
                ราคา
                {{
                  new Intl.NumberFormat("en-IN").format(product.selling_price)
                }}บาท
              </h2>
              
              <br />
              <!-- <h4 class="card-text text-danger mb-0">
                (ราคาปกติ
                {{ new Intl.NumberFormat("en-IN").format(product.price) }}บาท)
              </h4>
      
              <p class="card-text mb-0">
                รวมภาษีมูลค่าเพิ่ม {{ product.vat }}% แล้ว
              </p> -->
  
              <div class="item-options text-center" @click="payment_options(product.id)">
               
                <b-button variant="warning">
                  <feather-icon icon="ShoppingCartIcon" class="mr-50" />
                  <span>ซื้อ</span>
                </b-button>
              </div>
            </div>
          </div>
        </swiper-slide>
  
        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </b-card>
  </template>
    
    <script>
  import {
    BCardBody,
    BCardText,
    BCard,
    BImg,
    BLink,
    BRow,
    BCol,
    BButton,
  } from "bootstrap-vue";
  import { Swiper, SwiperSlide } from "vue-awesome-swiper";
  import api from "@/api";
  export default {
    components: {
      BCardBody,
      BCardText,
      BCard,
      BImg,
      BLink,
      BRow,
      BCol,
      BButton,
      // 3rd Party
      Swiper,
      SwiperSlide,
    },
    setup() {
      const swiperOptions = {
        slidesPerView: 1,
        // spaceBetween: 25,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          1024: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      };
  
      return {
        swiperOptions,
      };
    },
    data() {
      return {
        data: [],
      };
    },
    mounted() {
      this.loaddata();
    },
  
    methods: {
      async loaddata() {
        api
          .get("products", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("accessToken"),
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log("response.data :>> ", response.data);
            this.data = response.data.products;
          })
          .catch((error) => {
            console.log("error :>> ", error);
          });
      },
      payment_options(id){
        this.$router.push("/sales/checkout?id="+id);
      }
    },
  };
  </script>
    
    <style lang="scss">
  @import "@core/scss/vue/libs/swiper.scss";
  @import "~swiper/css/swiper.css";
  </style>
    